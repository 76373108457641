.item {
    display: flex;
    flex-direction: column;

    &__media {
        color: $white;
        display: block;
        position: relative;
        text-decoration: none;

        &:hover {
            color: $white;
            text-decoration: none;
        }
    }

    &__image {
        z-index: 1;
        display: block;
        position: relative;
    }

    &__tags {
        top: rem(20);
        left: rem(4);
        z-index: 10;
        display: block;
        font-style: normal;
        position: absolute;
        font-weight: 700;
        font-size: rem(22);
        line-height: rem(24);
    }

    &__tag {
        height: rem(48);
        padding: rem(4) rem(20);
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: rem(10);
        background: #FFB802;
    }

    &__content {
        flex-grow: 1;
        padding-top: rem(20);
        padding-bottom: rem(32);
    }

    &__title {
        color: $black;
        display: block;
        text-align: center;
        font-size: rem(22);
        line-height: rem(24);
        text-decoration: none;
        margin-bottom: rem(40);

        &:hover {
            color: $black;
            text-decoration: none;
        }
    }

    &__options {
        display: flex;
    }

    &__option {
        width: 50%;
        flex-shrink: 0;
        text-align: center;
        position: relative;

        &:first-child {

            &:before {
                top: 0;
                right: rem(-2);
                content: '';
                width: rem(4);
                height: rem(96);
                position: absolute;
                background: #C4C4C4;
            }
        }
    }

    &__icon {
        font-size: 0;
        line-height: 0;
        height: rem(58);
        display: inline-block;
    }

    &__size {
        display: block;
        font-size: rem(20);
        line-height: rem(24);
        margin-bottom: rem(10);
    }

    &__price {
        font-weight: 700;
        font-size: rem(20);
        line-height: rem(24);
    }

    &__button {
        text-align: center;

        .btn {
            max-width: rem(360);
            width: 100%;
        }
    }
}

.item-promo {
    color: $white;
    background: $brown;
    text-align: center;
    font-size: rem(26);
    line-height: rem(28);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: rem(354);
    margin-bottom: rem(20);

    &__icon {
        font-size: 0;
        line-height: 0;
        height: rem(68);
        margin-bottom: rem(44);
        display: inline-block;
    }

    &__button {
        z-index: 10;
        left: rem(15);
        right: rem(12);
        bottom: rem(-20);
        position: absolute;
        text-align: center;
    }

    .btn {
        max-width: rem(360);
        width: 100%;
    }
}
