.works {
    margin-bottom: rem(60);

    &__header {
        margin-bottom: rem(24);
        text-align: center;
    }

    &__navigation {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: rem(32);
    }

    &__nav {
        margin: 0;
        padding: 0;
        font-size: 0;
        line-height: 0;
        border: none;
        outline: none;
        width: rem(36);
        height: rem(36);
        display: inline-block;
        background: transparent;

        i {
            width: rem(36);
            height: rem(36);
        }

        &--prev {

        }

        &--next {
            transform: rotate(180deg);
        }
    }

    &__counter {
        text-align: center;
        color: #878787;
        width: rem(68);
        font-size: rem(36);
        line-height: rem(36);
        padding: 0 rem(8);
        white-space: nowrap;

        &--total {
            color: $black;
            font-size: rem(18);
        }
    }

    &__slider {

    }

    @include media-breakpoint-up(md) {
        margin-bottom: rem(90);

        &__navigation {
            display: none;
        }

        &__header {
            text-align: left;
            margin-bottom: rem(40);
        }
    }

    @include media-breakpoint-up(xl) {
        margin-bottom: rem(140);
    }
}

.work {
    text-align: center;

    &__image {
        display: block;
        margin-bottom: rem(32);
    }

    &__date {
        color: #4D4D4D;
        font-size: rem(18);
        line-height: rem(20);
        margin-bottom: rem(14);
    }

    &__title {
        font-weight: 500;
        font-size: rem(22);
        line-height: rem(24);
    }

    &__text {
        display: none;
    }

    &__button {
        text-align: center;
        padding-top: rem(40);

        .btn {
            width: 100%;
            max-width: rem(360);
        }
    }
}
