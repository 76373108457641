.breadcrumb {
    padding: 0;
    margin: 0;
    color: #878787;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    li {
        display: block;

        &:not(:last-child) {

            &::after {
                content: '/';
                color: $brown;
                padding: 0 rem(6);
                display: inline-block;
            }
        }

        a {
            color: $brown;
            text-decoration: none;

            &:hover {
                color: $brown;
                text-decoration: underline;
            }
        }
    }
}
