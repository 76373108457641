
// Montserrat

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Thin'), local('Montserrat-Thin'),
    url('../fonts/Montserrat/Montserrat-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Thin Italic'), local('Montserrat-ThinItalic'),
    url('../fonts/Montserrat/Montserrat-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'),
    url('../fonts/Montserrat/Montserrat-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat ExtraLight Italic'), local('Montserrat-ExtraLightItalic'),
    url('../fonts/Montserrat/Montserrat-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Light'), local('Montserrat-Light'),
    url('../fonts/Montserrat/Montserrat-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Light Italic'), local('Montserrat-LightItalic'),
    url('../fonts/Montserrat/Montserrat-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
    url('../fonts/Montserrat/Montserrat-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Italic'), local('Montserrat-Italic'),
    url('../fonts/Montserrat/Montserrat-Italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Medium Italic'), local('Montserrat-MediumItalic'),
    url('../fonts/Montserrat/Montserrat-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
    url('../fonts/Montserrat/Montserrat-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat SemiBold Italic'), local('Montserrat-SemiBoldItalic'),
    url('../fonts/Montserrat/Montserrat-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Bold'), local('Montserrat-Bold'),
    url('../fonts/Montserrat/Montserrat-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'),
    url('../fonts/Montserrat/Montserrat-BoldItalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat ExtraBold Italic'), local('Montserrat-ExtraBoldItalic'),
    url('../fonts/Montserrat/Montserrat-ExtraBoldItalic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Black Italic'), local('Montserrat-BlackItalic'),
    url('../fonts/Montserrat/Montserrat-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Medium'), local('Montserrat-Medium'),
    url('../fonts/Montserrat/Montserrat-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
    url('../fonts/Montserrat/Montserrat-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Black'), local('Montserrat-Black'),
    url('../fonts/Montserrat/Montserrat-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}
