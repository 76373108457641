.pretty-radio {
    font-size: 0;
    line-height: 0;
    font-weight: 400;
    position: relative;
    display: inline-block;

    &__input {
        @include visually-hidden();
    }

    &__label {
        color: #3a3a3a;
        font-size: rem(16);
        line-height: rem(20);
        position: relative;
        padding-left: rem(30);
        display: block;

        &::before {
            top: 0;
            left: 0;
            z-index: 1;
            content: '';
            width: rem(20);
            height: rem(20);
            display: block;
            position: absolute;
            border-radius: 50%;
            background: #C4C4C4;
            border: 1px solid #C4C4C4;
        }

        &:after {
            top: rem(5);
            left: rem(5);
            z-index: 2;
            content: '';
            width: rem(10);
            height: rem(10);
            display: block;
            opacity: 0;
            visibility: hidden;
            position: absolute;
            background: $brown;
            border-radius: 50%;
         //   transform: scale(3);
        }
    }


    &__input:checked + .pretty-radio__label {

        &::before {
            background: $brown;
            border-color: $brown;
        }

        &::after {
            opacity: 1;
            visibility: visible;
           // transform: scale(1);
            transition : all 0.3s cubic-bezier(0.35, 0.9, 0.4, 0.9);
        }
    }

    &__input:disabled + .pretty-radio__label {
        opacity: 0.4;
    }

    @include media-breakpoint-up(xxl) {

        &--xl {

            .pretty-radio {

                &__label {
                    font-size: rem(20);
                    line-height: rem(24);
                    padding-left: rem(42);

                    &:before {
                        width: rem(24);
                        height: rem(24);
                    }
                }
            }
        }
    }
}
