.product {
    margin-bottom: rem(45);

    &__breadcrumb {
        margin-bottom: rem(20);
    }

    &__title {
        margin-bottom: rem(24);
    }

    &__params {
        padding: 0;
        font-size: rem(14);
        line-height: rem(32);
        list-style: none;
        margin-bottom: rem(24);

        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;

            &:before {
                content: '';
                left: rem(4);
                right: rem(4);
                bottom: rem(8);
                border-bottom: 1px dashed #878787;
                display: block;
                position: absolute;
            }

            span {
                z-index: 10;
                display: block;
                background: $white;
                position: relative;
            }
        }
    }

    &__options {
        margin-bottom: rem(32);
    }

    &__price {
        margin-bottom: rem(12);
        white-space: nowrap;
        display: flex;
        justify-content: space-between;

        &--item {
            width: calc(50% - 0.625rem);
            flex-shrink: 0;
        }

        &--label {
            font-size: rem(14);
            line-height: rem(16);
        }

        &--new {
            color: $black;
            font-weight: 700;
            font-size: rem(18);
            line-height: rem(20);
        }

        &--old {
            color: #646464;
            font-weight: 700;
            font-size: rem(18);
            line-height: rem(20);
            text-decoration: line-through;
        }
    }

    &__benefit {
        margin-bottom: rem(14);
        padding: rem(5) rem(10);
        text-align: center;
        font-weight: 700;
        font-size: rem(12);
        line-height: rem(12);
        background: #F0EFEF;
        border-radius: rem(5);
    }

    &__note {
        color: #646464;
        font-size: rem(12);
        line-height: rem(18);
        margin-bottom: rem(30);
    }

    &__navs {
        display: none;
    }

    &__nav {
        display: flex;
        font-size: rem(18);
        line-height: rem(24);
        align-items: center;
        stroke: $brown;
        text-decoration: none;

        i {
            font-size: 0;
            line-height: 0;
            height: rem(36);
            display: inline-block;
            flex-shrink: 0;
        }

        span {
            display: inline-block;
            white-space: nowrap;
            padding: 0 rem(4);
        }

        &--next {

            i {
                transform: rotate(180deg);
            }
        }
    }

    &__heading {
        text-align: center;
        padding-top: rem(40);
        margin-bottom: rem(40);
    }

    &__tabs {
        display: flex;
        align-items: center;
        font-size: rem(14);
        line-height: rem(20);
        margin-bottom: rem(8);

        &--nav {
            width: 50%;
            border: none;
            outline: none;
            cursor: pointer;
            height: rem(62);
            display: block;
            background: $gray;

            strong {
                display: block;
            }

            &.active {
                color: $white;
                background: $brown;
            }
        }
    }

    &__desktop {
        display: none;
    }

    &__advance {
        display: none;
    }

    @include media-breakpoint-up(md) {
        margin-bottom: rem(80);

        &__benefit {
            padding: rem(12) rem(10);

            br {
                display: none;
            }
        }

        &__tabs {

            &--nav {
                width: auto;
                padding: rem(8) rem(24);

                strong {
                    display: inline;
                }
            }
        }

        &__heading {
            text-align: left;
        }

        &__price {

            &--old {
                font-size: rem(24);
                line-height: rem(28);
            }

            &--new {
                font-size: rem(24);
                line-height: rem(28);
            }
        }
    }

    @include media-breakpoint-up(xl) {
        margin-bottom: rem(120);
        display: flex;
        justify-content: space-between;

        &__left {
            width: 55%;
            flex-shrink: 0;
        }

        &__right {
            width: 45%;
            flex-shrink: 0;
            padding-left: rem(24);
        }

        &__mobile {
            display: none;
        }

        &__navs {
            padding-top: rem(24);
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__desktop {
            display: block;
        }

        &__advance {
            padding: rem(8) rem(36);
            display: block;
            text-align: center;
            background: $gray;
            font-size: rem(16);
            line-height: rem(20);

        }
    }

    @include media-breakpoint-up(xxl) {

        &__left {
            width: calc(100% - 585px);
        }

        &__right {
            width: 585px;
        }

        &__tabs {
            margin-bottom: rem(36);

            &--nav {
                font-size: rem(18);
                line-height: rem(24);
            }
        }

        &__heading {
            text-align: left;
        }

        &__params {
            font-size: rem(16);
            line-height: rem(32);
        }

        &__price {

            &--old {
                font-size: rem(26);
                line-height: rem(32);
            }

            &--new {
                font-size: rem(26);
                line-height: rem(32);
            }
        }

        &__advance {
            width: rem(755);
        }
    }

    @media (min-width: 1800px){

        &__advance {
            width: rem(890);
        }
    }
}

.product-media {
    margin-bottom: rem(14);

    &__gallery {
        margin-bottom: rem(14);
    }

    &__thumb {

        .swiper-slide {
            cursor: pointer;
        }
    }

    @include media-breakpoint-up(xxl) {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        &__gallery {
            margin: 0;
            width: calc(100% - 206px);
        }

        &__thumb {
            width: 190px;
            height: rem(458);
        }
    }

    @media (min-width: $wide) {

        &__gallery {
            width: rem(890);
        }

        &__thumb {
            width: rem(230);
            height: rem(538);
        }
    }

}

.product-options {

    &:not(:last-child) {
        margin-bottom: rem(32);
    }

    &__header {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:before {
            left: 0;
            right: 0;
            top: 50%;
            z-index: 1;
            content: '';
            height: 1px;
            display: block;
            position: absolute;
            background: #C4C4C4;
        }
    }

    &__title {
        z-index: 10;
        background: $white;
        position: relative;
        display: block;
        padding-right: rem(10);
    }

    &__toggle {
        font-size: 0;
        line-height: 0;
        border: none;
        outline: none;
        z-index: 10;
        stroke: $white;
        width: rem(26);
        height: rem(26);
        cursor: pointer;
        border-radius: 50%;
        background: $brown;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
            height: rem(16);
            display: inline-block;
            transition: .3s;
            transform: rotate(0deg);
        }
    }

    &__item {
        font-size: rem(14);
        line-height: rem(17);
        text-align: center;
        display: flex;
        flex-direction: column;
    }

    &__icon {
        font-size: 0;
        line-height: 0;
        height: rem(45);
        display: inline-block;
        margin-bottom: rem(10);
    }

    &__price {
        font-weight: 700;
        font-size: rem(18);
        line-height: rem(28);
    }

    &__text {
        color: #5E5E5E;
        flex-grow: 1;
    }

    &__name {
        color: $black;
        font-weight: 700;
        font-size: rem(16);
        line-height: rem(20);
        margin-bottom: rem(2);
    }

    &__wrap {
        padding-top: rem(16);
        display: grid;
        grid-column-gap: rem(20);
        grid-row-gap: rem(24);
        grid-template-columns: 1fr;
    }

    &.open {

        .product-options {

            &__toggle {

                i {
                    transition: .3s;
                    transform: rotate(180deg);
                }
            }
        }
    }

    @include media-breakpoint-up(md) {

        &__wrap {
            grid-template-columns: 1fr 1fr;

        }

        &__item {
            text-align: left;
            margin-bottom: 0;
        }
    }
}

.product-summary {
    color: $black;
    background: $gray;
    padding: rem(24);
    text-align: center;
    border-radius: rem(10);

    &__text {
        font-size: rem(16);
        line-height: rem(20);
    }

    &__price {
        font-weight: 700;
        font-size: rem(26);
        line-height: rem(32);
    }

    &__content {
        margin-bottom: rem(14);
    }

    @include media-breakpoint-up(md) {
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: rem(48);

        &__content {
            margin-bottom: 0;
        }
    }
}

.equipment {

    &__row {
        padding: rem(12) 0;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    &__image {
        width: 95px;
    }

    &__title {
        width: calc(100% - 95px);
        padding-left: rem(24);
        font-weight: 700;
        font-size: rem(18);
        line-height: rem(22);
    }

    &__text {
        width: 100%;
        padding-top: rem(24);
    }

    @include media-breakpoint-up(xxl) {
        font-size: rem(18);
        line-height: rem(22);

        &__row {
            flex-wrap: nowrap;
        }

        &__title {
            width: rem(300);
            flex-shrink: 0;
        }

        &__text {
            padding: 0 0 0 rem(24);
            flex-grow: 1;
        }
    }
}
