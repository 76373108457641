
.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;

    &::before {
        display: block;
        content: "";
    }

    .embed_responsive_item,
    iframe,
    embed,
    object,
    video {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}

.embed-responsive_21by9 {
    &::before {
        padding-top: percentage(9 / 21);
    }
}

.embed-responsive_16by9 {
    &::before {
        padding-top: percentage(9 / 16);
    }
}

.embed-responsive_4by3 {
    &::before {
        padding-top: percentage(3 / 4);
    }
}

.embed-responsive_1by1 {
    &::before {
        padding-top: percentage(1 / 1);
    }
}


.img-fluid {
    height: auto;
    display: block;
    max-width: 100%;
}

.ico-svg {
    position: relative;
    display: inline-block;
    width: auto;
    height: 100%;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
}

.hide {
    opacity: 0 !important;
    display: none !important;
    visibility: hidden !important;
}

// Visually hidden

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
    @include visually-hidden();
}

// Alignment

.text-center    { text-align: center !important; }
.text-right     { text-align: right !important; }
.text-justify   { text-align: justify !important; }
.text-nowrap    { white-space: nowrap !important; }


// Transformation

.text-lowercase  { text-transform: lowercase !important; }
.text-uppercase  { text-transform: uppercase !important; }
.text-capitalize { text-transform: capitalize !important; }

.mb-0 			 { margin-bottom: 0px !important; }
.mb-10 			 { margin-bottom: size-rel(10px) !important; }
.mb-20 			 { margin-bottom: size-rel(20px) !important; }
.mb-30 			 { margin-bottom: size-rel(30px) !important; }
.mb-40 			 { margin-bottom: size-rel(40px) !important; }
.mb-50 			 { margin-bottom: size-rel(50px) !important; }
.mb-60 			 { margin-bottom: size-rel(60px) !important; }

.mb-1 { margin-bottom: 1rem !important; }
.mb-15 { margin-bottom: 1.5rem !important; }
.mb-2 { margin-bottom: 2rem !important; }
.mb-25 { margin-bottom: 2.5rem !important; }
.mb-3 { margin-bottom: 3rem !important; }
.mb-35 { margin-bottom: 3.5rem !important; }
.mb-4 { margin-bottom: 4rem !important; }

.d-flex { display: flex;}
.align-center { align-items: center;}
.align-start { align-items: flex-start;}
.align-end { align-items: flex-end;}
.justify-content-between { justify-content: space-between };
.justify-content-start { justify-content: flex-start };
.justify-content-end { justify-content: flex-end };
.justify-content-center { justify-content: center };
