[data-spoiler-content] {
	overflow: hidden;
	max-height: 0;
	opacity: 0;
	will-change: max-height;
	transition: .3s;
}

[data-spoiler] {

	&.open {

		[data-spoiler-content] {
			opacity: 1;
			transition: .3s;
		}
	}
}

.spoilers {

	&__item {
		border-bottom: 3px solid #f0f0f0;
		margin: 0 0 rem(8);

		&.open {

			.spoilers__icon {
				transform: rotate(-180deg);
				transition: .3s;
			}
		}
	}

	&__header {
		width: 100%;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		padding: rem(8) 0;
		color: #000;
		border: none;
		outline: none;
		text-align: left;
		background: transparent;
		font-weight: 700;
		font-size: rem(18);
		line-height: rem(24);
	}

	&__title {
		display: block;
		padding-right: rem(8);
	}

	&__icon {
		font-size: 0;
		line-height: 0;
		height: rem(24);
		display: inline-block;
		transition: .3s;
		fill: #000;
		flex-shrink: 0;
	}

	&__content {
	}

	&__wrap {
		padding: rem(8) 0;
	}
}