// Options
$enable-grid-classes:         true !default;


// colors

$white:         #fff !default;
$black:         #000 !default;
$primary:       #000 !default;
$secondary:     #82523E !default;
$brown:         #82523E !default;
$gray:          #F0EFEF !default;
$green:         #42BD2E !default;

$shadow:        0px 0px 15px rgba(0, 0, 0, 0.5) !default;


$html-font-size: 16px;
$font-size-base:            1rem !default;
$line-height-base:          1.5 !default;

$font-family-primary: 'Montserrat', sans-serif;
$font-family-secondary: 'Montserrat', sans-serif;

$border-radius: rem(4);
$transition-time: 0.2s;
$box-shadow: 0 0 0 #fff;

$paragraph-margin-bottom:   rem(20) !default;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: (
    0: 0,
    1: $spacer / 4,
    2: $spacer / 2,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 3,
) !default;

// Grid

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1240px,
    xxl: 1600px
) !default;

$container-max-widths: (
    sm: 540px,
    md: 750px,
    lg: 960px,
    xl: 1200px,
    xxl: 1570px
) !default;

$wide: 1800px !default;


$grid-columns:                12 !default;
$grid-gutter-width:           1.5rem !default;
$grid-row-columns:            6 !default;

$gutters: $spacers !default;

$container-padding-x: $grid-gutter-width !default;


