@import 'config';
@import './layout/functions';
@import './mixins/mixins';

// Plugins
@import 'node_modules/swiper/swiper';
@import 'node_modules/@fancyapps/ui/src/Fancybox/Fancybox';
@import './plugins/simplebar';
@import './plugins/air-datepicker';

// Layout
@import './layout/fonts';
@import './layout/base';
@import './layout/utilities';

@import './components/fullscreen';
@import './components/containers';
@import './components/grid';
@import './components/buttons';
@import './components/forms/forms';
@import './components/breadcrumbs';
@import './components/tabs';
@import './components/collapse';
@import './components/spoilers';
@import './components/dropdown';

@import './parts/item';
@import './parts/product';
@import './parts/works';
@import './parts/showcase';
@import './parts/portfolio';
@import './parts/modal';

@import 'header';
@import 'footer';

@import 'content';


