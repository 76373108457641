.btn {
    color: $white;
    outline: none;
    cursor: pointer;
    font-weight: 700;
    font-size: rem(14);
    line-height: rem(24);
    min-height: rem(56);
    padding: rem(12) rem(24);
    text-transform: uppercase;
    user-select: none;
    text-align: center;
    white-space: nowrap;
    position: relative;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    border: rem(1) solid transparent;
    background: $black;
    border-radius: 0;
    fill: $white;

    &:hover {
        color: $white;
        background: $brown;
        border-color: $brown;
        text-decoration: none;
        fill: $white;
    }

    &:active {
        background: $black;
    }

    &:focus,
    &.focus {
        outline: 0;
   //     box-shadow: 0 0 0 rem(4) rgba(149, 93, 69, 0.5);
    }

    span {
        display: inline-block;
    }

    i {
        font-size: 0;
        line-height: 0;
        display: inline-block;
        height: rem(24);
    }

    span + i {
        margin-left: rem(6);
    }

    i + span {
        margin-left: rem(6);
    }
}

.btn-shadow {
    box-shadow: $shadow;
}

.btn-link {
    margin: 0;
    padding: 0;
    color: $black;
    border: none;
    outline: none;
    cursor: pointer;
    display: inline-flex;
    background: transparent;
    text-decoration: underline;

    &:hover {
        color: $black;
        text-decoration: none;
    }
}
