input:not([type=checkbox]):not([type=radio]),
textarea,
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input, textarea {
    outline: none;
    &:focus:required:invalid { border-color: red }
    &:required:valid { border-color: green }
}

option::selection {background: #ccc;}
option::-moz-selection {background: #ccc;}
option::-webkit-selection {background: #ccc; color:#fff;}

.field {
    display: block;
    position: relative;
    background: transparent;
    $input-selector: "input[type=text], input[type=email], input[type=password], textarea";

    #{$input-selector} {
        margin: 0;
        width: 100%;
        height: rem(56);
        outline: none;
        color: #4D4D4D;
        box-shadow: none;
        font-weight: 400;
        font-size: rem(16);
        line-height: rem(24);
        padding: rem(15) rem(20);
        background: #F0EFEF;
        display: inline-block;
        vertical-align: middle;
        border: rem(1) solid #F0EFEF;
        border-radius: 0;
        @include placeholder(#4D4D4D);

        &::-ms-clear {
            display: none;
            width: 0;
            height: 0;
        }

        &:focus,
        &:active {
            outline: none;
            @include placeholder(transparent);
            box-shadow: 0 0 0 rem(4) rgba(149, 93, 69, 0.5);
        }


        &.error {
            border-color: red !important;
        }
    }

    textarea {
        height: auto;
        resize: none;
    }

    &--line {

        #{$input-selector} {
            border: none;
            padding-left: 0;
            padding-right: 0;
            background: transparent;
            border-bottom: 1px solid $black;
            &:focus,
            &:active {
                outline: none;
                @include placeholder(transparent);
                box-shadow: none !important;
            }
        }
    }

    &--white {

        #{$input-selector} {
            color: $white;
            border-bottom-color: $white;
            @include placeholder($white);

            &:focus,
            &:active {
                @include placeholder(transparent);
            }
        }
    }

    &--black {

        #{$input-selector} {
            border-color: #C4C4C4;
        }
    }
}

.file-field {
    cursor: pointer;
    white-space: nowrap;
    font-size: rem(13);
    line-height: rem(16);
    max-width: 100%;
    font-weight: 400;
    overflow: hidden;
    position: relative;
    display: inline-flex;
    align-items: center;
    fill: none;
    stroke: $white;
    color: $white;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }

    input {
        top: 0;
        left: 0;
        width: 1px;
        height: 1px;
        opacity: 0;
        display: block;
        overflow: hidden;
        position: absolute;
    }

    &__icon {
        font-size: 0;
        height: rem(24);
        overflow: hidden;
        margin-right: rem(6);
        text-overflow: ellipsis;
        max-width: calc(100% - 5.5rem);
    }

    &--black {
        stroke: $brown;
        color: $brown;
        border-color: rgba(0, 0, 0, 0.16);
    }
}
