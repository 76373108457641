.footer {
    color: $black;
    background: $gray;
    font-size: rem(14);
    line-height: rem(20);

    &__primary {
        text-align: center;
        padding-top: rem(8);
        padding-bottom: rem(32);
    }

    &__secondary {
        padding: rem(36) 0;
        text-align: center;
        border-top: 1px solid #4D4D4D;
    }

    &__copy {
        white-space: nowrap;
        font-weight: 400;
        font-size: rem(22);
        line-height: rem(26);
    }

    &__privacy {
        display: none;
        color: #4D4D4D;
        max-width: rem(870);
        font-size: rem(20);
        line-height: rem(26);
    }

    &__logo {
        color: $black;
        font-size: 0;
        line-height: 0;
        display: block;
        width: rem(200);
        margin: 0 auto rem(24) auto;
    }

    &__group {

        &--nav {
            display: none;
        }
    }

    &__title {
        display: none;
    }

    &__contact {
        margin: 0;
        padding: 0;
        font-size: 0;
        line-height: 0;
        list-style: none;

        > li {

            &:not(:last-child) {
                margin-bottom: rem(30);
            }
        }
    }

    &__phone {
        color: $black;
        font-weight: 700;
        font-size: rem(28);
        line-height: rem(28);
        white-space: nowrap;
        text-decoration: none;
        display: inline-block;
        margin-bottom: rem(4);
    }

    &__callback {
        color: $brown;
        font-weight: 400;
        font-size: rem(18);
        line-height: rem(18);
        white-space: nowrap;
        text-decoration: underline;

        &:hover {
            color: $brown;
            text-decoration: none;
        }
    }

    &__label {
        font-size: rem(18);
        line-height: rem(18);
        margin-bottom: rem(16);
    }

    &__value {
        color: $brown;
        font-weight: 500;
        font-size: rem(20);
        line-height: rem(20);

        a {
            color: $brown;
            text-decoration: none;

            &:hover {
                color: $brown;
                text-decoration: underline;
            }
        }
    }

    &__social {
        font-size: 0;
        line-height: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &--item {
            fill: #898989;
            height: rem(66);
            margin: 0 rem(12);
            display: inline-block;

            &:hover {
                fill: $brown;
            }
        }
    }

    &__nav {
        display: none;
    }

    @include media-breakpoint-up(xl) {

        &__primary {
            padding-top: rem(48);
            padding-bottom: rem(42);
        }

        &__row {
            display: flex;
            text-align: left;
            justify-content: space-between;
        }

        &__secondary {
            text-align: left;
            padding: rem(44) 0;

            &--row {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }

        &__privacy {
            display: block;
        }

        &__group {

            &--nav {
                display: block;
            }
        }

        &__logo {
            margin: 0 0 rem(36) auto;
        }

        &__title {
            display: block;
            font-weight: 700;
            font-size: rem(22);
            line-height: rem(24);
            text-transform: uppercase;
            margin-top: rem(70);
            height: rem(70);
        }

        &__nav {
            display: flex;
            font-size: rem(18);
            line-height: rem(24);
            justify-content: space-between;

            &--menu {

            }

            &--size {
                width: rem(110);
            }

            ul {
                margin: 0;
                padding: 0;
                list-style: none;

                li {

                    &:not(:last-child) {
                        margin-bottom: rem(6);
                    }
                }

                a {
                    color: $black;
                    text-decoration: none;

                    &:hover {
                        color: $brown;
                        text-decoration: none;
                    }
                }
            }
        }

        &__social {
            margin-left: rem(-24);
            justify-content: flex-start;

            &--item {
                margin: 0 rem(36) 0 0;
            }
        }
    }

    @include media-breakpoint-up(xxl) {

        &__nav {
            font-size: rem(22);
            line-height: rem(24);

            ul {

                li {

                    &:not(:last-child) {
                        margin-bottom: rem(16);
                    }
                }
            }
        }
    }
}
