.showcase {
    margin-bottom: rem(60);

    &__header {
        margin-bottom: rem(24);
        text-align: center;
    }

    &__title  {
        margin-bottom: rem(24);
    }

    &__navigation {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: rem(32);
    }

    &__nav {
        margin: 0;
        padding: 0;
        font-size: 0;
        line-height: 0;
        border: none;
        outline: none;
        width: rem(36);
        height: rem(36);
        cursor: pointer;
        display: inline-block;
        background: transparent;

        i {
            width: rem(36);
            height: rem(36);
        }

        &--prev {

        }

        &--next {
            transform: rotate(180deg);
        }
    }

    &__counter {
        text-align: center;
        color: #878787;
        width: rem(68);
        font-size: rem(36);
        line-height: rem(36);
        padding: 0 rem(8);
        white-space: nowrap;

        &--total {
            color: $black;
            font-size: rem(18);
        }
    }

    &__link {
        color: $brown;
        font-size: rem(22);
        line-height: rem(26);
        text-decoration: underline;
        display: block;
        text-align: center;

        &:hover {
            color: $brown;
            text-decoration: none;
        }

        &--xl {
            display: none;
        }
    }

    &__slider {
        margin-bottom: rem(30);
    }

    @include media-breakpoint-up(md) {
        margin-bottom: rem(90);

        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: rem(40);
        }

        &__content {
            display: flex;
            align-items: center;
        }

        &__title {
            margin: 0 rem(24) 0 0;
        }

        &__navigation {
            margin: 0;
        }
    }

    @include media-breakpoint-up(xl) {
        margin-bottom: rem(140);

        &__header {
            margin-bottom: rem(50);
        }

        &__link {

            &--xl {
                display: block;
            }

            &--sm {
                display: none;
            }
        }
    }

    @include media-breakpoint-up(xl) {
    }
}
