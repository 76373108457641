.header {
    z-index: 800;
    background: $white;
    position: relative;
    margin-bottom: rem(14);

    .container {
        padding: 0;
    }

    &__wrap {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__logo {
       display: block;
    }

    &__content {
        padding-right: rem(12);
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__contact {
        margin: 0;
        padding: 0;
        font-size: 0;
        line-height: 0;
        list-style: none;
        text-align: right;

        li {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            &:first-child {
                margin-bottom: rem(4);
            }
        }
    }

    &__messenger {
        display: none;
        font-size: 0;
        line-height: 0;
        width: rem(28);
        height: rem(28);
        margin-right: rem(16);
        fill: $green;
        flex-shrink: 0;
    }

    &__phone {
        color: $black;
        font-weight: 700;
        font-size: rem(14);
        line-height: rem(14);
        white-space: nowrap;
        display: inline-block;
        text-decoration: none;

        &:hover {
            color: $black;
            text-decoration: none;
        }
    }

    &__callback {
        color: $brown;
        font-weight: 400;
        font-size: rem(14);
        line-height: rem(14);
        white-space: nowrap;
        display: inline-block;
        text-decoration: underline;

        &:hover {
            color: $brown;
            text-decoration: none;
        }
    }

    &__hamburger {
        margin-left: rem(16);
    }

    &__menu {
        display: none;
    }

    &__nav {
        font-size: rem(16);
        line-height: rem(24);

        > ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            align-items: center;

            > li {
                margin-right: rem(24);

                a {
                    color: $black;
                    height: rem(56);
                    white-space: nowrap;
                    text-decoration: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &:hover {
                        color: $brown;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    &__button {
        display: none;
    }

    @include media-breakpoint-up(md) {

        &__wrap {

        }

        &__messenger {
            display: inline-block;
        }

    }

    @include media-breakpoint-up(lg) {

    }

    @include media-breakpoint-up(xl) {
        margin-bottom: rem(32);

        .container {
            padding-left: rem(12);
            padding-right: rem(12);
        }

        &__wrap {
            align-items: inherit;
        }

        &__main {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }


        &__content {
            padding-top: rem(30);
        }

        &__hamburger {
            display: none;
        }

        &__menu {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__nav {
            position: relative;
        }

        &__logo {

        }

        &__main {
            flex-grow: 1;
            padding-left: rem(32);
        }

        &__content {
     //       padding-left: rem(170);
        }

        &__row {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__phone {
            font-size: rem(20);
            line-height: rem(20);
        }

        &__callback {
            font-size: rem(16);
            line-height: rem(16);
        }
    }

    @include media-breakpoint-up(xxl) {
        margin-bottom: rem(40);

        &__content {
            padding-top: rem(60);
        }

        &__menu {
            padding-top: rem(24);
        }

        &__nav {
            font-size: rem(20);
            line-height: rem(24);

            > ul {

                > li {
                    margin-right: rem(36);
                }
            }
        }

        &__button {
            display: block;
        }
    }

    @media (min-width: 1800px) {

        &__phone {
            font-size: rem(28);
            line-height: rem(28);
        }

        &__callback {
            font-size: rem(18);
            line-height: rem(18);
        }

        &__nav {
            font-size: rem(22);

            > ul {

                > li {
                    margin-right: rem(48);
                }
            }
        }

    }
}

.header-logo {
    display: flex;
    align-items: center;
    text-decoration: none;
    position: relative;
    font-weight: 700;
    font-size: 0;
    line-height: 0;
    text-transform: uppercase;

    &:before {
        top: rem(4);
        left: rem(4);
        z-index: 1;
        content: '';
        width: rem(130);
        height: rem(80);
        position: absolute;
        background: $brown;
        opacity: 0.5;
    }

    &__media {
        z-index: 10;
        font-size: 0;
        line-height: 0;
        width: rem(130);
        height: rem(80);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $brown;
        margin-right: rem(12);
    }

    &__image {
        width: rem(120);
        display: inline-block;
    }

    @include media-breakpoint-up(md) {

        &:before {
            top: rem(8);
            left: rem(8);
            width: rem(120);
            height: rem(120);
        }

        &__media {
            width: rem(120);
            height: rem(120);
        }

        &__image {
            width: rem(110);
        }

    }

    @include media-breakpoint-up(xl) {

        &:before {
            top: rem(8);
            left: rem(8);
            width: rem(160);
            height: rem(160);
        }

        &__media {
            width: rem(160);
            height: rem(160);
            padding-bottom: rem(20);
        }

        &__image {
            width: rem(150);
            height: auto;
        }

        &__text {
            top: rem(50);
            left: rem(200);
            position: absolute;
        }
    }

    @include media-breakpoint-up(xxl) {

        &:before {
            top: rem(12);
            left: rem(12);
            width: rem(208);
            height: rem(208);
        }

        &__media {
            width: rem(208);
            height: rem(208);
        }

        &__image {
            width: rem(200);
        }

        &__text {
            top: rem(70);
            left: rem(262);
            position: absolute;
        }
    }
}

.header-elem {
    display: none;
    margin-right: rem(40);

    &__icon {
        height: rem(24);
        flex-shrink: 0;
        margin-right: rem(16);
    }

    &__label {
        font-size: rem(12);
        line-height: rem(24);
    }

    &__value {
        color: $brown;
        font-size: rem(14);
        line-height: rem(20);

        a {
            color: $brown;
            text-decoration: none;

            &:hover {
                color: $brown;
                text-decoration: none;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        display: flex;
        align-items: flex-start;
        white-space: nowrap;

    }

    @include media-breakpoint-up(xl) {

        &__label {
            font-size: rem(14);
        }
    }

    @include media-breakpoint-up(xxl) {
        margin-right: rem(64);

        &__label {
            font-size: rem(16);
        }

        &__value {
            font-size: rem(20);
        }
    }

    @media (min-width: 1800px) {

        &:first-child {
            margin-right: rem(140);
        }
    }
}

.hamburger {
    width: rem(24);
    height: rem(24);
    position: relative;
    display: block;
    text-align: right;
    margin: 0;
    padding: 0;
    font-size: 0;
    line-height: 0;
    border: none;
    outline: none;
    cursor: pointer;
    background: transparent;

    span,
    &:after,
    &:before {
        right: 0;
        left: calc(50% - 0.5625rem);
        height: 2px;
        content: '';
        width: rem(18);
        display: inline-block;
        position: absolute;
        transition-property: transform;
        transition-duration: .15s;
        transition-timing-function: ease;
        border-radius: 3px;
        background-color: $brown;
    }

    span {
        top: calc(50% - 1px);
    }

    &:after {
        top: rem(6);
    }

    &:before {
        top: rem(16)
    }

    &.hamburger--open {

    }
}

.nav-mobile {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 900;
    color: $white;
    position: fixed;
    background: $brown;
    overflow-y: auto;
    opacity: 0;
    visibility: hidden;

    &__header {
        top: 0;
        left: 0;
        right: 0;
        z-index: 50;
        padding: 0 rem(12);
        height: rem(60);
        position: fixed;
        background: $brown;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__logo {
        color: $white;
        font-weight: 700;
        font-size: rem(12);
        line-height: rem(14);
        text-decoration: none;
        text-transform: uppercase;
        display: flex;
        align-items: center;

        &:hover {
            color: $white;
            text-decoration: none;
        }

        i {
            font-size: 0;
            line-height: 0;
            width: rem(30);
            display: inline-block;
            margin-right: rem(10);
        }

        span {
            display: inline-block;
        }
    }

    &__close {
        border: none;
        outline: none;
        font-size: 0;
        line-height: 0;
        width: rem(24);
        height: rem(24);
        cursor: pointer;
        position: relative;
        display: inline-block;
        background: transparent;

        &:after,
        &:before {
            top: calc(50% - 1px);
            left: calc(50% - 10px);
            z-index: 5;
            content: '';
            width: 20px;
            height: 2px;
            display: block;
            position: absolute;
            background: $white;
        }

        &:after {
            transform: rotate(45deg);
        }

        &:before {
            transform: rotate(-45deg);
        }
    }

    &__main {
        padding: rem(90) rem(12) rem(32) rem(12);
    }

    &__menu {
        text-align: center;
        font-size: rem(16);
        line-height: rem(24);
        margin-bottom: rem(40);

        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            > li {

                &:not(:last-child) {
                    border-bottom: 1px solid #BA6D4D;
                }
            }

            a {
                color: $white;
                text-decoration: none;
                display: block;
                padding: rem(12) 0;

                &:hover {
                    color: $white;
                    text-decoration: none;
                }
            }
        }
    }

    &__contact {
        margin: 0;
        padding: 0;
        font-size: rem(16);
        line-height: rem(18);
        list-style: none;

        > li {
            display: flex;
            align-items: flex-start;

            &:not(:last-child) {
                margin-bottom: rem(40);
            }
        }
    }

    &__icon {
        font-size: 0;
        line-height: 0;
        height: rem(24);
        display: inline-block;
        margin-right: rem(18);
        flex-shrink: 0;
    }

    &__label {
        font-size: rem(14);
        line-height: rem(18);
        margin-bottom: rem(4);
    }

    &__value {

        a {
            color: $white;
            text-decoration: none;

            &:hover {
                color: $white;
                text-decoration: none;
            }
        }
    }

    &__phone {
        color: $white;
        font-weight: 700;
        font-size: rem(16);
        line-height: rem(18);
        text-decoration: none;
        display: inline-block;
        margin-bottom: rem(2);

        &:hover {
            color: $white;
            text-decoration: none;
        }
    }

    &__callback {
        color: $white;
        font-size: rem(14);
        line-height: rem(18);
        text-decoration: underline;

        &:hover {
            color: $white;
            text-decoration: none;
        }
    }
}

.nav-open {
    overflow: hidden;

    .header__nav {
        transition: .3s;
        transform: translateX(0);
    }

    .header__layout {
        opacity: 1;
        visibility: visible;
    }

    .hamburger {

        span {
            opacity: 0;
            width: 100%;
        }

        &:after {
            transform: translateY(5px) translateX(-2px) rotate(45deg);
            width: 100%;
        }

        &:before {
            transform: translateY(-5px) translateX(-2px) rotate(-45deg);
            width: 100%;
        }
    }

    .nav-mobile {
        opacity: 1;
        visibility: visible;
    }
}
