.portfolio-item {
    display: block;
    position: relative;
    text-decoration: none;


    &__content {
        display: none;
        opacity: 0;
        visibility: hidden;
    }

    @include media-breakpoint-up(md) {
    }

    @include media-breakpoint-up(xl) {
    }
}

.portfolio-grid {
    display: grid;
    grid-row-gap: rem(32);
    grid-column-gap: rem(12);
    grid-template-columns: 1fr;

    @include media-breakpoint-up(md) {
        grid-row-gap: rem(40);
        grid-column-gap: rem(12);
        grid-template-columns: 1fr 1fr;
    }


    @include media-breakpoint-up(xl) {
        grid-row-gap: rem(48);
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.work-grid {
    display: grid;
    grid-row-gap: rem(32);
    grid-column-gap: rem(20);
    grid-template-columns: 1fr;

    @include media-breakpoint-up(md) {
        grid-row-gap: rem(30);
        grid-column-gap: rem(30);
        grid-template-columns: 1fr 1fr;
    }

    @include media-breakpoint-up(xl) {
        grid-template-columns: 1fr 1fr 1fr;
    }
}
