.root {
    width: 100%;
    min-height: 100vh;
    max-width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    &__main {
        flex-grow: 1;
    }

    &__content {
        padding: rem(40) 0 rem(60);
    }

    @include media-breakpoint-up(xl) {

        &__content {
            padding-bottom: rem(100);
        }
    }
}

.cat-heading  {
    color: $white;
    position: relative;
 //   background: url("../img/catalog_heading.webp") 50% 50% no-repeat;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;

    &__title {
        margin: 0;
        text-align: center;
    }

    &__wrap {
        height: rem(430);
        position: relative;
        padding-top: rem(146);
    }

    &__main {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: rem(124);
        background: rgba(0, 0, 0, 0.5);
        border-radius: rem(5) rem(5) 0 0;
    }

    &__button {
        margin: 0;
        padding: 0;
        color: $white;
        border: none;
        outline: none;
        cursor: pointer;
        text-align: center;
        background: transparent;

        &.open {

            .cat-heading__arrow {
                transform: rotate(180deg);
            }
        }
    }

    &__text {
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        display: block;
        margin-bottom: rem(24);
    }

    &__icon {
        font-size: 0;
        line-height: 0;
        width: rem(42);
        height: rem(42);
        border-radius: 50%;
        border: 1px solid $brown;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    &__arrow {
        height: rem(24);
        font-size: 0;
        line-height: 0;
        display: inline-block;
        stroke: $brown;
    }

    @include media-breakpoint-up(xl) {


        &__wrap {
            height: rem(600);
            padding-top: rem(180);
        }

        &__main {
            height: rem(130);
        }

        &__text {
            font-weight: 700;
            font-size: rem(24);
            line-height: rem(28);
            margin-bottom: rem(24);
        }

        &__icon {
        }

        &__title {
            font-size: rem(64);
            line-height: rem(68);
        }
    }

    @include media-breakpoint-up(xxl) {

        &__wrap {
            height: rem(858);
            padding-top: rem(240);
        }
    }
}

.swiper {
    width: 100%;
    height: 100%;
}

.catalog {
    padding-top: rem(25);
    padding-bottom: rem(50);

    @include media-breakpoint-up(md) {
        padding-top: rem(40);
        margin-bottom: rem(80);
    }

    @include media-breakpoint-up(xl) {
        padding-top: rem(80);
        margin-bottom: rem(120);
    }

    @include media-breakpoint-up(xxl) {
        padding-top: rem(100 );
        margin-bottom: rem(160);
    }
}

.catalog-grid {
    margin-bottom: rem(40);
    display: grid;
    grid-row-gap: rem(40);
    grid-column-gap: rem(14);
    grid-template-columns: 1fr;

    @include media-breakpoint-up(md) {
        margin-bottom: rem(60);
        grid-row-gap: rem(60);
        grid-template-columns: 1fr 1fr;
    }

    @include media-breakpoint-up(xl) {
        margin-bottom: rem(70);
        grid-row-gap: rem(100);
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.impulse {
    color: $white;
    position: relative;
    margin-bottom: rem(80);
    padding: rem(32) rem(0) 0 rem(14);

    &:before {
        top: 0;
        z-index: 1;
        content: '';
        right: 0;
        left: rem(-15);
        bottom: rem(15);
        position: absolute;
        background: $brown;
    }

    &__wrap {
        z-index: 10;
        position: relative;
        margin-right: rem(-12);
        padding: rem(40) rem(24) rem(60) rem(24);
        background: rgba(163, 110, 88, 0.8)
    }

    &__header {
        margin-bottom: rem(24);
    }

    &__text {
        font-size: rem(18);
        line-height: rem(32);
        margin-bottom: rem(28);
    }

    &__button {

        .btn {
            width: 100%;
        }

        &:not(:last-child) {
            margin-bottom: rem(22);
        }
    }

    @include media-breakpoint-up(md) {
        padding: rem(48) rem(48) 0 rem(48);

        &:before {
            top: 0;
            left: 0;
            right: 0;
            bottom: rem(56);
        }

        &__wrap {
            margin-right: 0;
            padding: rem(40) rem(40) rem(56) rem(40);
        }
    }

    @include media-breakpoint-up(xl) {
        margin-bottom: rem(120);

        &__wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__content {
            padding-right: rem(30);
        }

        &__text {
            margin: 0;
            max-width: rem(750);
        }
    }

    @include media-breakpoint-up(xxl) {
        margin-bottom: rem(160);
        padding: rem(48) rem(52) 0 rem(52);

        &__wrap {
            padding: rem(74) rem(60) rem(140) rem(80);
        }
    }
}

.calc {

    &__header {
        text-align: center;
        margin-bottom: rem(30);
    }

    &__body {
        max-height: rem(220);
    }

    &__group {
        margin-bottom: rem(48);
    }

    &__title {
        font-weight: 700;
        font-size: rem(22);
        line-height: rem(32);
        margin-bottom: rem(24);
    }

    &__list {
        margin: 0;
        padding: 0;
        list-style: none;

        > li {

            &:not(:last-child) {
                margin-bottom: rem(24);
            }
        }
    }

    &__secondary {
        margin-bottom: rem(68);

        &--item {

            &:not(:last-child) {
                margin-bottom: rem(24);
            }
        }
    }

    &__end {
        display: none;
        background: $gray;
        position: relative;
        padding: rem(50) 0;

        &:after,
        &:before {
            top: 0;
            bottom: 0;
            z-index: 1;
            content: '';
            width: 100%;
            position: absolute;
            background: $gray;
        }

        &:after {
            left: 100%;
        }

        &:before {
            right: 100%;
        }

        &--header {
            text-align: center;
            margin-bottom: rem(40);
        }

        &--input {
            margin-bottom: rem(30);
        }

        &--wrap {
            z-index: 10;
            position: relative;
        }

        .btn {
            width: 100%;
        }
    }

    @include media-breakpoint-up(md) {

        &__primary {
            margin-left: rem(-20);
            margin-right: rem(-20);
            display: flex;
            flex-wrap: wrap;
        }

        &__group {
            width: 33.33%;
            padding: 0 rem(20);
        }

        &__end {
            padding-bottom: rem(80);

            &--main {
                width: rem(324);
                margin: 0 auto;
            }
        }

        &__body {

            &--size {
                max-width: rem(170);
            }
        }

        &__secondary {

            &--row {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }

            &--item {
                margin-right: rem(40);
            }

            &--input {
                width: rem(514);
                flex-shrink: 0;
            }
        }
    }

    @include media-breakpoint-up(xl) {

        &__header {
            text-align: left;
            margin-bottom: rem(32);
        }

        &__primary {
            margin-bottom: rem(80);
            flex-wrap: nowrap;
            justify-content: space-between;
        }

        &__group {
            width: auto;
            margin-bottom: 0;
        }

        &__secondary {
            margin-bottom: rem(80);

            &--row {
                align-items: center;
            }

            &--item {

                &:not(:last-child) {
                    margin-bottom: 0;
                }
            }
        }
    }

    @include media-breakpoint-up(xxl) {

        &__title {
            margin-bottom: rem(30);
        }

        &__list {

            > li {

                &:not(:last-child) {
                    margin-bottom: rem(30);
                }
            }
        }

        &__body {
            max-height: rem(260);
        }
    }
}

.text-block {
    font-size: rem(16);
    line-height: rem(30);
    padding-top: rem(64);

    &__main {
        position: relative;
        padding-top: rem(64);
        padding-bottom: rem(64);

        &:before {
            z-index: 1;
            content: '';
            top: 0;
            left: rem(-6);
            width: rem(184);
            height: rem(454);
            max-height: 80%;
            position: absolute;
            background: $gray;
        }
    }

    &__content {
        z-index: 10;
        padding-left: rem(20);
        position: relative;

        p {

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    @include media-breakpoint-up(md) {
        padding-top: rem(90);
        padding-bottom: rem(90);

        &__main {
            padding-bottom: rem(84);

            &:before {
                height: 100%;
                width: rem(360);
                max-height: inherit;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        font-size: rem(18);
        line-height: rem(32);
        padding-bottom: rem(120);

        &__main {
            padding-top: rem(64);
            padding-bottom: rem(80);

            &:before {
                left: 0;
                width: rem(610);
            }
        }

        &__content {
            padding-left: rem(190);
        }
    }

    @include media-breakpoint-up(xxl) {
        padding-bottom: rem(160);
    }
}

.pagination {

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: center;

        li {
            margin: 0 rem(5);

            a {
                width: rem(40);
                height: rem(40);
                display: flex;
                align-items: center;
                justify-content: center;
                color: $brown;
                font-size: rem(18);
                line-height: rem(24);
                border-radius: rem(3);
                text-decoration: none;
                border: 1px solid $brown;
            }

            &.active {

                a {
                    color: $white;
                    background: $brown;
                }
            }
        }
    }

    @include media-breakpoint-up(xl) {

        ul {

            li {

                a {
                    width: rem(48);
                    height: rem(48);
                    font-size: rem(20);
                }
            }
        }
    }
}

.filter {
    display: none;
    padding-top: rem(30);
    padding-bottom: rem(20);

    &__title {
        font-weight: 700;
        font-size: rem(22);
        line-height: rem(32);
        margin-bottom: rem(24);
    }


    &__group {
        margin-bottom: rem(48);
    }

    &__body {
        max-height: rem(230);
    }

    &__list {
        margin: 0;
        padding: 0;
        list-style: none;

        > li {

            &:not(:last-child) {
                margin-bottom: rem(24);
            }
        }
    }


    @include media-breakpoint-up(md) {

        &__row {
            margin-left: rem(-20);
            margin-right: rem(-20);
            display: flex;
            flex-wrap: wrap;
        }

        &__group {
            width: 33.33%;
            padding: 0 rem(20);
        }
    }

    @include media-breakpoint-up(xl) {
        padding-top: rem(60);
        padding-bottom: 0;

        &__body {
            max-height: rem(280);
        }

        &__row {
            flex-wrap: nowrap;
            justify-content: space-between;
        }

        &__group {
            width: auto;
            margin-bottom: 0;
            flex-grow: 1;
        }
    }
}

.simplebar-track {

    &.simplebar-vertical {
        width: rem(4);
    }
}

.simplebar-scrollbar {

    &:before {
        left: 0;
        right: 0;
        background: #955D45;
    }
}

.offer {
    color: $white;
    position: relative;
    padding-top: rem(66);
    margin-bottom: rem(90);

    &__image {
        top: 0;
        left: rem(12);
        z-index: 20;
        width: rem(334);
        position: absolute;
    }

    &__main {
        background: $brown;
        padding: rem(40) rem(12) rem(20) rem(12);
    }

    &__wrap {
        padding: rem(160) rem(18) rem(76) rem(18);
        background: rgba(163, 110, 88, 0.8);
    }

    &__title {
        font-weight: 400;
        font-size: rem(32);
        line-height: rem(36);
        margin-bottom: rem(18);
    }

    &__text {
        font-size: rem(16);
        line-height: rem(32);
    }

    &__content {
        margin-bottom: rem(24);
    }

    &__button {

        .btn {
            width: 100%;
        }
    }

    @include media-breakpoint-up(md) {

        &__main {
            padding: rem(40) rem(40) rem(40) rem(40);
        }

        &__image {
            left: calc(50% - 200px);
            width: rem(400);
        }

        &__wrap {
            padding: rem(200) rem(40) rem(80) rem(40);
        }
    }
    @include media-breakpoint-up(xl) {
        padding-right: rem(140);
        margin-bottom: rem(140);

        &__image {
            top: auto;
            bottom: rem(-75);
            left: auto;
            width: rem(500);
            right: rem(-185);
        }

        &__main {
            padding: 0 0 0 rem(40);
        }

        &__wrap {
            padding: rem(40) rem(220) rem(80) rem(40);
            transform: translateY(40px);
        }

        &__button {

            .btn {
                width: rem(254);
            }
        }

        &__text {
            font-size: rem(16);
            line-height: rem(32);
        }
    }

    @include media-breakpoint-up(xxl) {
        padding-right: rem(204);

        &__image {
            width: rem(601);
            bottom: rem(-80);
        }

        &__wrap {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
        }

        &__content {
            margin-bottom: 0;
            max-width: rem(680);
        }

        &__title  {
            font-size: rem(48);
            line-height: rem(56);
        }

        &__text {
            font-size: rem(26);
            line-height: rem(32);
        }
    }

    @media (min-width: $wide) {

        &__image {
            bottom: rem(-94);
        }

        &__main {
            padding-left: rem(52 );
        }

        &__wrap {
            transform: translateY(52px);
            justify-content: flex-start;
        }

        &__button {
            padding-left: rem(40);
        }
    }
}

.promo {
    padding-top: rem(60);
    padding-bottom: rem(60);
    background: url("../img/promo__bg__sm.webp") 50% 0 no-repeat;
    background-size: cover;

    &__xl {
        display: none;
    }

    &__wrap {
        position: relative;
    }

    &__header {
        font-size: rem(24);
        line-height: rem(28);
        margin-bottom: rem(20);

        &--primary {
            display: block;
            font-size: rem(36);
            line-height: rem(40);
        }

        &--secondary {
            display: block;
        }
    }

    &__text {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: rem(14);
        line-height: rem(20);

        li {
            position: relative;
            padding-left: rem(16);

            &:before {
                top: 0;
                left: 0;
                content: '•';
                font-size: rem(20);
                line-height: rem(20);
                position: absolute;
            }

            &:not(:last-child) {
                margin-bottom: rem(16);
            }
        }
    }

    &__image {
        max-width: rem(340);
        margin: 0 auto;

        &--xl {
            display: none;
        }
    }

    &__button {
        padding-top: rem(20);
        text-align: center;

        &--xl {
            display: none;
        }

        .btn {
            width: rem(300);
        }
    }

    @include media-breakpoint-up(md) {
        margin-bottom: rem(68);
        position: relative;
        overflow: hidden;
        background: url("../img/promo__bg.webp") 50% 100% no-repeat;
        background-size: cover;

        &__content {
            z-index: 10;
            width: rem(360);
            position: relative;
        }

        &__image {
            margin: 0;
            right: 0;
            bottom: 0;
            width: rem(460);
            max-width: inherit;
            position: absolute;

            &--sm {
                display: none;
            }

            &--xl {
                display: block;
            }
        }

        &__button {
            text-align: left;

            &--xl {
                display: block;
            }

            &--sm {
                display: none;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        margin-top: rem(-100);
        padding-top: rem(160);
        padding-bottom: rem(100);
        background-position: 50% 0;
        background-size: auto;

        &__content {
            width: rem(700);
        }

        &__header {
            font-size: rem(48);
            line-height: rem(62);

            &--primary {
                font-size: rem(64);
                line-height: rem(68);
            }
        }

        &__text {
            font-size: rem(18);
            line-height: rem(32);

            li {
                padding-left: rem(20);

                &:before {
                    font-size: rem(32);
                    line-height: rem(32);
                }
            }
        }

        &__button {
            padding-top: rem(40);
        }

        &__image {
            right: rem(-50);
            width: rem(780);
        }
    }
    @include media-breakpoint-up(xxl) {
        padding-top: rem(180);
        padding-bottom: rem(176);

        &__image {
            width: rem(1135);
        }
    }

    @media (min-width: 1800px) {
        background-size: cover;

        &__content {
            width: rem(800);
        }
    }
}

.bid {
    background: $gray;
    position: relative;
    margin-bottom: rem(60);
    padding: rem(40) rem(12) rem(20) rem(12);

    &__image {
        max-width: rem(360);
        margin: 0 auto rem(20) auto;
    }

    &__header {
        font-size: rem(32);
        line-height: rem(36);
        margin-bottom: rem(20);
    }

    &__text {
        color: #4D4D4D;
        font-size: rem(18);
        line-height: rem(26);
        margin-bottom: rem(24);
    }

    &__button {

        .btn {
            width: 100%;
        }
    }

    @include media-breakpoint-up(md) {
        margin-bottom: rem(100);
        padding: 0 rem(40) rem(40) rem(40);

        &__image {
            top: rem(-30);
            position: relative;
            max-width: inherit;
            width: rem(460);
        }

        &__content {
            z-index: 20;
            position: relative;
            text-align: center;
        }

        &__button {

            .btn {
                width: auto;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        margin-bottom: rem(140);
        padding: 0;
        background: transparent;

        &__main {
            z-index: 1;
            background: $gray;
            position: relative;
            padding: rem(70) rem(70) rem(60) rem(70);
        }

        &__image {
            z-index: 10;
            top: auto;
            bottom: rem(-40);
            width: rem(860);
            right: rem(-180);
            position: absolute;
        }

        &__content {
            max-width: rem(400);
            text-align: left;
        }
    }

    @include media-breakpoint-up(xxl) {
        padding-right: rem(100);
        margin-bottom: rem(180);

        &__header {
            font-size: rem(48);
            line-height: rem(56);
        }

        &__image {
            width: rem(1032);
        }

        &__content {
            max-width: rem(640);
        }

        &__text {
            font-size: rem(26);
            line-height: rem(32);
        }

        &__main {
            padding: rem(74) rem(70) rem(80) rem(70);
        }
    }

    @media (min-width: 1800px){
        padding-left: rem(92);
        padding-right: rem(160);

        &__image {
            right: rem(-60);
        }
    }
}

.color-brown {
    color: $brown !important;
}

.about {
    margin-bottom: rem(60);

    &__media {
        max-width: rem(400);
        position: relative;
        padding-left: rem(25);
        padding-bottom: rem(36);
        margin-bottom: rem(46);

        &:before {
            left: 0;
            bottom: 0;
            content: '';
            top: rem(46);
            right: rem(70);
            background: $gray;
            position: absolute;
        }
    }

    &__image {
        z-index: 10;
        position: relative;
    }

    &__header {
        margin-bottom: rem(24);
    }

    &__text {
        font-size: rem(16);
        line-height: rem(32);

        p {
            margin-bottom: rem(24);

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        margin-bottom: rem(110);
        display: flex;
        align-items: flex-start;

        &__media {
            width: rem(500);
            flex-shrink: 0;
            max-width: inherit;
            margin-bottom: 0;
            padding-left: rem(80);
            padding-bottom: rem(80);

            &:before {
                top: rem(80)
            }
        }

        &__content {
            padding-left: rem(62);
        }
    }

    @include media-breakpoint-up(xl) {

        &__media {
            padding-top: rem(20);
        }
    }
}

.video {
    margin-bottom: rem(80);

    &__header {
        margin-bottom: rem(32);
    }

    &__item {
        display: block;
        position: relative;

        i {
            top: calc(50% - 20px);
            left: calc(50% - 20px);
            font-size: 0;
            line-height: 0;
            width: rem(40);
            height: rem(40);
            display: block;
            position: absolute;
        }
    }

    @include media-breakpoint-up(xl) {
        margin-bottom: rem(170);

        &__item {

            i {
                top: calc(50% - 42px);
                left: calc(50% - 42px);
                width: rem(85);
                height: rem(85);
            }
        }
    }
}

.region {
    background: $gray;
    position: relative;
    margin-bottom: rem(110);

    &__map {
        z-index: 1;
        top: rem(142);
        left: calc(50% - 161px);
        width: rem(322);
        position: absolute;
    }

    &__content {
        z-index: 10;
        color: $black;
        font-size: rem(18);
        line-height: rem(26);
        position: relative;
        padding: rem(90) rem(20) rem(40) rem(20);
    }

    &__header {
        margin-bottom: rem(32);
    }

    &__form {
        z-index: 20;
        color: $white;
        background: $brown;
        position: relative;
        padding: rem(48) rem(14) rem(80) rem(14);
        box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.35);

        &--wrap {
            margin: 0 auto;
            max-width: rem(530);
        }

        form {
            margin: 0 auto;
            max-width: rem(450);
        }
    }

    &__title {
        font-weight: 500;
        font-size: rem(32);
        line-height: rem(36);
        text-align: center;
        margin-bottom: rem(24);
    }

    &__info {
        font-size: rem(16);
        line-height: rem(22);
        text-align: center;
        margin-bottom: rem(24);
    }

    &__control {
        margin-bottom: rem(16);
    }

    .btn {
        width: 100%;
    }

    @include media-breakpoint-up(md) {

        &__content {
            padding: rem(90) rem(40) rem(60) rem(40);
        }

        &__form {
            padding: rem(48) rem(40) rem(80) rem(40);
        }

        &__map {
            top: rem(80);
            left: calc(50% - 210px);
            width: rem(420);
        }
    }

    @include media-breakpoint-up(xl) {
        margin-bottom: 0;
        padding-bottom: 0;
        display: flex;
        justify-content: space-between;

        &__form {
            width: rem(670);
            margin-top: rem(-74);
            flex-shrink: 0;
            padding-top: rem(74);
        }

        &__content {
            height: rem(724);
            padding-left: 0;
        }

        &__title {
            margin-bottom: rem(42);
        }

        &__button {
            padding-top: rem(20);
        }

        &__map {
            top: rem(102);
            left: rem(158);
            width: rem(862);
        }

        &__text {
            max-width: rem(728);
        }
    }

    @include media-breakpoint-up(xxl) {
        padding-right: rem(108);

        &__content {
            padding-top: rem(128);
        }
    }
}

.region-section {
    @include media-breakpoint-up(xl) {
        background: $gray;
        padding-bottom: rem(64);
    }
}

.video-grid {
    display: grid;
    grid-row-gap: rem(32);
    grid-column-gap: rem(20);
    grid-template-columns: 1fr;

    @include media-breakpoint-up(md) {
        grid-row-gap: rem(30);
        grid-column-gap: rem(30);
        grid-template-columns: 1fr 1fr;
    }

    @include media-breakpoint-up(xl) {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.video-item {
    display: block;
}

@include media-breakpoint-up(sm) {}
@include media-breakpoint-up(md) {}
@include media-breakpoint-up(lg) {}
@include media-breakpoint-up(xl) {}
@include media-breakpoint-up(xxl) {}

@media (min-width: 1800px){}
