.modal {
    width: 100%;
    max-width: rem(655);
    background: $white;
    padding: rem(40) rem(30);

    &__header {
        text-align: center;
        font-size: rem(32);
        line-height: rem(38);
        margin-bottom: rem(24);
    }

    &__product {
        padding: 0;
        list-style: none;
        font-size: rem(14);
        line-height: rem(22);
        margin: 0 rem(-10) rem(28) rem(-10);

        li {
            padding: 0 rem(10);
        }
    }

    &__text {
        text-align: center;
        font-size: rem(18);
        line-height: rem(28);
        margin-bottom: rem(24);
    }

    &__main {
        margin: 0 auto;
        max-width: rem(400);
    }

    .btn {
        width: 100%;
    }

    &__footer {
        text-align: center;
    }

    &__info {
        text-align: center;
        margin-bottom: rem(20);

        &--item {

            &:not(:last-child) {
                margin-bottom: rem(6);
            }
        }
    }

    @include media-breakpoint-up(md) {
        padding-top: rem(44);
        padding-bottom: rem(56);

        &__product {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;

            li {

            }
        }
    }

    @include media-breakpoint-up(xl) {

        &__header {
            font-size: rem(48);
            line-height: rem(56);
        }

        &__text {
            font-size: rem(24);
            line-height: rem(24);
        }
    }
}

.success {
    color: $white;
    text-align: center;
    background: $brown;

    &__icon {
        display: block;
        width: rem(48);
        margin: 0 auto rem(18) auto;
    }

    &__title {
        font-weight: 500;
        font-size: rem(32);
        line-height: rem(38);
        margin-bottom: rem(12);
    }

    &__text {
        font-size: rem(18);
        line-height: rem(22);
    }

    @include media-breakpoint-up(xl) {

        &__title {
            font-size: rem(48);
            line-height: rem(56);
        }

        &__text {
            font-size: rem(24);
            line-height: rem(30);
        }
    }
}
